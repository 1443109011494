import {Injectable, Injector} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpResponse, HttpRequest, HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, finalize, map, tap} from "rxjs/operators";
import {AuthService} from "@common/services";


@Injectable()
export class JwtRefreshTokenResponseInterceptor implements HttpInterceptor {

    authService: AuthService;

    constructor(injector: Injector) {
        this.authService = injector.get<AuthService>(AuthService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('/api/token/refresh')) {
            return next.handle(req)
                .pipe(
                    catchError((error) => {
                        if (error instanceof HttpErrorResponse && error.status === 401) {
                            this.authService.clearAuthData();
                        }
                        throw error;
                    })
                );

        }

        return next.handle(req);
    }
}
