import {Injectable, Injector} from '@angular/core';
import {ShipFromStoreOrder} from '@common/core/models';
import {IBitfGraphQlRequest, IBitfGraphQlResponse} from '@common/interfaces';
import {BitfGraphQlService} from '@common/libs/bitforce/core/services/graph-ql/bitf-graph-ql.service';
import {Observable} from "rxjs";
import {gql} from "apollo-angular";
import {map, tap} from "rxjs/operators";
import {SHIP_FROM_STORE_DETAILS_FIELDS, SHIP_FROM_STORE_LIST_FIELDS} from "@common/core/services";


@Injectable({
    providedIn: 'root',
})

export class ShipFromStoreService extends BitfGraphQlService {
    constructor(public injector: Injector) {
        super(injector);
    }

    getById(requestParams: IBitfGraphQlRequest) {
        requestParams.modelMapper = 'getShipFromStore';
        requestParams.query = gql`
            ${SHIP_FROM_STORE_DETAILS_FIELDS}
            query Query($id: Int!) {
                getShipFromStore(id: $id) {
                    ...ShipFromStoreFields
                }
            }
        `;

        return super.query<ShipFromStoreOrder>(requestParams);
    }

    patchShipFromStoreOrder(
        requestParams: IBitfGraphQlRequest<Partial<ShipFromStoreOrder>> = {},
        order: Partial<ShipFromStoreOrder>
    ) {
        if (typeof requestParams.id === 'string') {
            requestParams.id = parseInt(requestParams.id, 10);
        }
        requestParams.modelMapper = 'updateShipFromStore';
        requestParams.mutation = gql`
            mutation Mutate($id: Int!, $input: UpdateShipFromStoreInput!) {
                updateShipFromStore(id: $id, input: $input) {
                    success
                    output {
                        status
                    }
                }
            }
        `;
        return super.mutate<ShipFromStoreOrder>(requestParams, order).pipe(
            tap((response: IBitfGraphQlResponse<ShipFromStoreOrder>) => {
                Object.assign(order, response.content);
            })
        );
    }

    count(requestParams: IBitfGraphQlRequest): Observable<number> {
        requestParams.modelMapper = 'getShipFromStoreListing';
        requestParams.query = gql`
            query Query($filter: String) {
                getShipFromStoreListing(filter: $filter) {
                    totalCount
                }
            }
        `;
        return super
            .query<ShipFromStoreOrder[]>(requestParams)
            .pipe(map(response => response.originalBody.getShipFromStoreListing.totalCount));
    }

    getShipFromStoreOrders(
        requestParams: IBitfGraphQlRequest = {}
    ): Observable<IBitfGraphQlResponse<ShipFromStoreOrder[]>> {
        requestParams.modelMapper = 'getShipFromStoreListing';
        requestParams.query = gql`
            ${SHIP_FROM_STORE_LIST_FIELDS}
            query Query($first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String) {
                getShipFromStoreListing(
                    first: $first
                    after: $after
                    sortBy: $sortBy
                    sortOrder: $sortOrder
                    filter: $filter
                ) {
                    edges {
                        node {
                            ...ShipFromStoreFields
                        }
                    }
                    totalCount
                }
            }
        `;
        return super.query<ShipFromStoreOrder[]>(requestParams);
    }
}
