import {Component, Input, OnInit} from '@angular/core';
import {ICustomer} from '@common/interfaces';
import {bitfToTranslate} from '@common/libs/bitforce/utils/bitf-translate.utils';
import {ISimpleListItem} from '@web/shared/components/simple-list/simple-list.interface';

@Component({
    selector: 'soh-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() className = '';
    items: ISimpleListItem[];

    constructor() {
    }

    ngOnInit(): void {
        const {fullName, phone, email} = this.customer;
        this.items = [
            {
                label: bitfToTranslate('CUSTOMER_DETAILS.NAME_LASTNAME'),
                value: fullName,
            },
            {
                label: bitfToTranslate('CUSTOMER_DETAILS.PHONE_NUMBER'),
                value: phone || '-',
                specialLink: phone ? `tel:${phone}` : undefined,
            },
            {
                label: bitfToTranslate('COMMON.LABEL.EMAIL'),
                value: email || '-',
                specialLink: email ? `mailto:${email}` : undefined,
            },
        ];
    }
}
