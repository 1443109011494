import {SuperModel} from './super-model.model';
import {OrderItem} from "@common/core/models/order-item.model";

export class ShipFromStoreItem extends SuperModel {

    orderItem: OrderItem;
    qty: number;
    productSku: string;


    constructor(data: any = {}) {
        super(data);
        if (data.orderItem) {
            this.orderItem = new OrderItem(data.orderItem);
        }
    }

    get serialised() {
        return {};
    }
}
