import {gql} from "apollo-angular";
import {environment} from "@env/environment";

const {
    apiProperties: {sizeQuery, colorQuery},
} = environment;

export const SHIP_FROM_STORE_LIST_FIELDS = gql`
    fragment ShipFromStoreFields on object_ShipFromStore {
        id
        orderNumber
        status
        creationDate
        customerFullName
        newAt
        order {
            ... on object_OnlineShopOrder {
                id
                orderState
                ordernumber
            }
        }
    }
`;

export const SHIP_FROM_STORE_NESTED_ORDER = gql`
    fragment OrderFields on object_OnlineShopOrder {
        id
        ordernumber
        orderdate
        order_customer_firstname
        order_customer_lastname
        orderState
        orderhistory {
            event
            eventdate
        }
        paymentInfo {
            ... on fieldcollection_PaymentInfo {
                message
                amount
                currency
                paymentState
                gateway
                kind
            }
        }
        currency
        deliveryCompany
        deliveryStreet
        deliveryCity
        deliveryZip
        deliveryProvince
        deliveryCountry
        shipped
        customerFirstname
        customerLastname
        customerFullName
        customerEmail
        customerStreet
        customerCity
        customerZip
        customerCompany
        customerProvince
        customerProvinceCode
        customerCountry
        customerPhone
        totalPrice
        origin
        items {
            ... on object_OnlineShopOrderItem {
                id
                amount
                productName
                totalPrice
                product {
                    ... on object_Product {
                        sku
                        images {
                            ... on fieldcollection_ImageInfo {
                                image {
                                    filename
                                    fullpath
                                }
                            }
                        }
                        ${colorQuery}
                        ${sizeQuery}
                        parent {
                            ... on object_Product {
                                sku
                                images {
                                    ... on fieldcollection_ImageInfo {
                                        image {
                                            filename
                                            fullpath
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SHIP_FROM_STORE_ITEMS = gql`
    fragment ShipFromStoreItems on object_ShipFromStoreItem {
        id
        qty
        productSku
        orderItem {
            ... on object_OnlineShopOrderItem {
                id
                amount
                productName
                totalPrice
                product {
                    ... on object_Product {
                        sku
                        images {
                            ... on fieldcollection_ImageInfo {
                                image {
                                    filename
                                    fullpath
                                }
                            }
                        }
                        ${colorQuery}
                        ${sizeQuery}
                        parent {
                            ... on object_Product {
                                sku
                                images {
                                    ... on fieldcollection_ImageInfo {
                                        image {
                                            filename
                                            fullpath
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SHIP_FROM_STORE_DETAILS_FIELDS = gql`
    ${SHIP_FROM_STORE_NESTED_ORDER}
    ${SHIP_FROM_STORE_ITEMS}
    fragment ShipFromStoreFields on object_ShipFromStore {
        id
        customerFullName
        orderNumber
        status
        newAt
        packedAt
        sentToLogisticAt
        canceledAt
        creationDate
        assignmentHistory {
            event
            eventDate
        }
        items {
            ...ShipFromStoreItems
        }
        shippingLabel {
            ... on asset {
                filename
                fullpath
            }
        }
        order {
            ...OrderFields
        }
    }
`;


