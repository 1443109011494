import {Injectable, Injector} from '@angular/core';
import {BitfJwtAuthService} from '@bitf/services/auth/jwt/bitf-jwt-auth.service';
import {EBitfAuthState} from '@common/enums';
import {IBitfApiResponse, IBitfJwtLogin} from '@common/interfaces';
import {PimcoreJwtService} from '@services';
import {BITF_CONFIGS} from '@web/configs';
import {User} from '@web/core/models';
import {Observable} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {environment} from "@env/environment";
import {BitfTryCatch} from "@bitf/core/decorators";

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BitfJwtAuthService {

    REFRESH_TOKEN: string = `${environment.appName}-RefreshToken`;
    REFRESH_TOKEN_EXPIRATION: string = `${environment.appName}-RefreshTokenExpiration`;
    REMEMBER_ME: string = `${environment.appName}-RememberMe`;


    constructor(protected injector: Injector, private pimcoreJwtService: PimcoreJwtService) {
        super(injector);
    }

    async renewToken(): Promise<IBitfJwtLogin> {
        if ((this.getRememberMe() || this.isTokenRecentlyExpired()) && !this.isRefreshTokenExpired()) {
            this.authTokenMetaData = undefined;
            return new Promise((success, error) => {
                this.pimcoreJwtService.refreshToken(this.getRefreshToken()).subscribe(
                    (response: any) => {
                        this.setRefreshToken(response.content.refresh_token);
                        this.setRefreshTokenExpiration(response.content.refresh_token_expiration);
                        this.signIn({encodedToken: response.content.token});
                        success(response)
                    },
                    e => {
                        this.clearAuthData();
                        error(e);
                    }
                );
            });

        } else {
            this.signOut();
            return Promise.reject();
        }
    }

    isTokenRecentlyExpired(): boolean {
        const tokenExpiration = this.authTokenMetaData.expiresAt;
        let tokenExpirationTimeLimit = environment.tokenExpirationTimeLimit;
        /** Default to 10 minutes */
        if (tokenExpirationTimeLimit == null) {
            tokenExpirationTimeLimit = 600000;
        }
        const limitExpirationDate = Date.now() - tokenExpirationTimeLimit;

        return tokenExpiration > limitExpirationDate;
    }

    isRefreshTokenExpired(): boolean {
        const refreshToken: string = this.getRefreshToken();
        const refreshTokenExpiration: number = this.getRefreshTokenExpiration();

        if (refreshToken && refreshTokenExpiration) {
            return (Date.now() / 1000) > refreshTokenExpiration;
        }
        return true;
    }

    getTokenUsername() {
        if (this.authTokenMetaData && this.authTokenMetaData.username) {
            return this.authTokenMetaData.username;
        } else {
            return "";
        }

    }

    clearAuthData() {
        this.removeRefreshTokenData();
        this.setUser(undefined);
        this.authTokenMetaData = undefined;
        if (this.refreshTokenHandler) {
            clearTimeout(this.refreshTokenHandler);
        }
    }

    listenForAuthStateChange(): Observable<EBitfAuthState> {
        return this.authState$.pipe(
            tap((authState: EBitfAuthState) => {
                if (authState === EBitfAuthState.TOKEN_RETRIEVED && this.authTokenMetaData) {
                    this.usersService.getMe().subscribe(
                        (response: IBitfApiResponse<User>) => {
                            this.setUser(response.content);
                            this.onLoginSuccess();
                            this.handleRedirect();
                        },
                        () => {
                            this.onLoginError();
                        }
                    );
                }
            })
        );
    }

    setRememberMe(rememberMe): void {
        localStorage.setItem(this.REMEMBER_ME, rememberMe);
    }

    getRememberMe() {
        const rememberMe = localStorage.getItem(this.REMEMBER_ME).toLowerCase();
        if (rememberMe == 'true') {
            return true;
        } else {
            return false;
        }
    }

    setRefreshToken(refreshToken): void {
        localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    }

    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    setRefreshTokenExpiration(refreshTokenExpiration): void {
        localStorage.setItem(this.REFRESH_TOKEN_EXPIRATION, refreshTokenExpiration);
    }

    getRefreshTokenExpiration() {
        return parseInt(localStorage.getItem(this.REFRESH_TOKEN_EXPIRATION));
    }

    removeRefreshTokenData() {
        localStorage.removeItem(this.REFRESH_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN_EXPIRATION);
        localStorage.removeItem(this.REMEMBER_ME);
    }

    handleAuthentication(accessData) {
        this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
        this.pimcoreJwtService.getToken(accessData).subscribe(
            response => {
                this.setRememberMe(accessData.rememberMe);
                this.setRefreshToken(response.content.refresh_token);
                this.setRefreshTokenExpiration(response.content.refresh_token_expiration);

                this.signIn({encodedToken: response.content.token});
            },
            () => {
                this.onLoginError();
            }
        );
    }

    onLoginError(errorMessage = '', authState: EBitfAuthState = EBitfAuthState.LOGIN_ERROR) {
        this.authState$.next(authState);
        this.clearAuthData();

        // NOTE this is to prevent the user refresh the page in login error state with an invalid token
        // this will reload the page without queryParams (the token) so the page will redirect again to the
        // oauth service login page
        if (location.search) {
            this.router.navigateByUrl(BITF_CONFIGS.urls.signInUrl);
        }
    }
}
