import {NgModule, Optional, SkipSelf} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';
import {MatPaginatorIntl} from '@angular/material/paginator';

import {BitfMatPaginatorTranslations} from '@common/libs/bitforce/core/components/ui/paginator/material/bitf-mat-paginator.translations';

import {SharedModule} from '@shared/shared.module';
import {CommonCoreModule} from '@common/core/common-core.module';
import {throwIfAlreadyLoaded} from './module-config';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {JwtRequestInterceptor} from './interceptors/jwt-request-interceptor.service';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {JwtRefreshTokenResponseInterceptor} from "@core/interceptors/jwt-refresh-token-response-interceptor.service";

const EXPORTS_COMPONENTS = [NotFoundComponent];
const COMPONENTS = [];

@NgModule({
    imports: [CommonCoreModule, RouterModule, SharedModule.forRoot(), PortalModule],
    exports: [...EXPORTS_COMPONENTS],
    declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: BitfMatPaginatorTranslations
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtRequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtRefreshTokenResponseInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
            parentModule: CoreModule
    ) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
