export enum EApiCallStateNames {
    ORDERS_LIST = 'ORDERS_LIST',
    RETURNS_LIST = 'RETURNS_LIST',
    TICKETS_LIST = 'TICKETS_LIST',
    TICKET_ITEMS_LIST = 'TICKET_ITEMS_LIST',
    PICKUP_IN_STORE_LIST = 'PICKUP_IN_STORE_LIST',
    SHIP_FROM_STORE_LIST = 'SHIP_FROM_STORE_LIST',
    DASHBOARD_PICKUP_IN_STORE = 'DASHBOARD_PICKUP_IN_STORE',
    DASHBOARD_RETURNS = 'DASHBOARD_RETURNS',
    DASHBOARD_SHIP_FROM_STORE = 'DASHBOARD_SHIP_FROM_STORE',
    DASHBOARD_COUNT_RETURNS = 'DASHBOARD_COUNT_RETURNS',
    DASHBOARD_COUNT_PICKUP_IN_STORE = 'DASHBOARD_COUNT_PICKUP_IN_STORE',
    DASHBOARD_COUNT_SHIP_FROM_STORE = 'DASHBOARD_COUNT_SHIP_FROM_STORE',
}

export enum EApiRequestPartKeys {
    SEARCH = 'SEARCH',
    PAGINATION = 'PAGINATION',
    SORTING = 'SORTING',
    FILTERS = 'FILTERS',
    SHIP_FROM_STORE_DATE_SORTING = 'SHIP_FROM_STORE_DATE_SORTING',
    SHIP_FROM_STORE_STATUS_SORTING = 'SHIP_FROM_STORE_STATUS_SORTING',
    ORDER_NUMBER_SORTING = 'ORDER_NUMBER_SORTING',
    ORDER_DATE_SORTING = 'ORDER_DATE_SORTING',
    ORDER_PICKING_TYPOLOGY_SORTING = 'ORDER_PICKING_TYPOLOGY_SORTING',
    ORDER_STATE_SORTING = 'ORDER_STATE_SORTING',
    ORDER_TOTAL_PRICE_SORTING = 'ORDER_TOTAL_PRICE_SORTING',
    ORDER_CUSTOMER_EMAIL_SORTING = 'ORDER_CUSTOMER_EMAIL_SORTING',
    RETURN_NUMBER_SORTING = 'RETURN_NUMBER_SORTING',
    RETURN_ORDER_SORTING = 'RETURN_ORDER_SORTING',
    RETURN_REFUND_TYPE_SORTING = 'RETURN_REFUND_TYPE_SORTING',
    RETURN_STATUS_SORTING = 'RETURN_STATUS_SORTING',
    RETURN_DATE_SORTING = 'RETURN_DATE_SORTING',
    TICKET_ID_SORTING = 'TICKET_ID_SORTING',
    TICKET_ORDER_N_SORTING = 'TICKET_ORDER_N_SORTING',
    TICKET_DATE_SORTING = 'TICKET_DATE_SORTING',
    TICKET_EDIT_DATE_SORTING = 'TICKET_EDIT_DATE_SORTING',
    TICKET_STATUS_SORTING = 'TICKET_STATUS_SORTING',
    TICKET_ITEM_ID_SORTING = 'TICKET_ITEM_ID_SORTING',
    WAREHOUSE_FILTER = 'WAREHOUSE_FILTER',
}
